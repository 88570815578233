<template>
    <div class="flex justify-center p-12">
        <div class="flex w-[352px] flex-col items-center justify-center gap-6">
            <div
                class="flex h-12 w-12 flex-col items-center justify-center rounded-full border-8 border-primary-50 bg-primary-100 p-5"
            >
                <base-icon
                    class="text-primary-600"
                    name="line-icons:general:search-lg"
                    size="lg"
                />
            </div>
            <div class="flex flex-col gap-1 text-center">
                <div class="text-base font-medium">
                    {{ $t('rules.list.notFound.title') }}
                </div>
                <div class="text-sm font-normal leading-5 text-gray-500">
                    {{ $t('rules.list.notFound.description') }}
                </div>
            </div>
            <base-button
                v-if="$acl.can('create_rules')"
                variant="primary"
                size="sm"
                icon="line-icons/general/plus"
                :label="$t('rules.list.button.createRule')"
                @click="onPressRulesSlideOut"
            >
            </base-button>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['open-rule-slide'])
const onPressRulesSlideOut = () => {
    emit('open-rule-slide')
}
</script>
