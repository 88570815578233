<template>
    <slide-out-menu
        @close="onCloseRulesSlideOut"
        :title="t('rules.sidebar.form.header.edit')"
        :subtitle="t('rules.sidebar.form.header.supportingText')"
        :show="isShow"
        :loading="loading"
    >
        <rules-edit-form
            :rule-id="ruleId"
            ref="ruleRef"
            @refresh-rules="refreshRules"
            @rule-loading="loading = $event"
        />

        <template v-slot:slide-out-menu-footer>
            <div class="flex flex-row-reverse content-end gap-3">
                <base-button size="sm" @click="onSave">
                    {{ $t('slideOutMenu.save.label') }}
                </base-button>
                <base-button
                    variant="secondary-color"
                    size="sm"
                    @click.prevent="onCloseRulesSlideOut"
                >
                    {{ $t('slideOutMenu.cancel.label') }}
                </base-button>
            </div>
        </template>
    </slide-out-menu>
</template>

<script setup>
const { t } = useI18n()

const ruleRef = ref(null)
const emit = defineEmits(['slide-close', 'refresh-rules'])

defineProps({
    isShow: {
        type: Boolean,
        required: true,
    },
    ruleId: {
        type: String,
        required: true,
    },
})

const loading = ref(false)

const refreshRules = () => {
    emit('refresh-rules')
}

const onSave = () => {
    ruleRef.value.onSave()
}

const onCloseRulesSlideOut = () => {
    emit('slide-close')
}
</script>
