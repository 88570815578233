const navigation = [
    {
        id: 'sas-bookeeping-rules',
        path: 'bookeeping.rules',
        label: 'rules.sidebar.label',
        parent: 'sas-bookeeping',
        position: 70,
        permission: 'view_rules',
    },
]

export default navigation
