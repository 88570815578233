<template>
    <form-text-input
        size="sm"
        icon-name="line-icons:general:search-lg"
        :placeholder="$t('table.searchByName')"
        :model-value="filterValues.name"
        @change="onChange($event.target.value)"
    >
        {{ $t('general.search') }}
    </form-text-input>
</template>

<script setup>
const queries = {
    name: 'q',
}
const { filterValues, updateFilter } = useFilters(queries)

const onChange = (value) => updateFilter(queries.name, value)
</script>
