<template>
    <rules-form-general @set-errors="setErrors" />
</template>

<script setup>
import {
    CONDITION_OPERATOR,
    MATCHING_TYPE,
    MONEY_FLOW,
    RULE_TYPE,
    TRANSACTION_TYPE,
} from '@tenant/utils/constants'

const props = defineProps({
    overwriteInitialValues: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    setHighlight: {
        type: Object,
        required: false,
        default: () => ({}),
    },
})

const { t } = useI18n()
const initialValues = {
    name: '',
    money_flow_type: MONEY_FLOW.IN,
    payment_account_id: null,
    conditions_match_type: MATCHING_TYPE.ALL,
    auto_add: false,
    rule_action: {
        transaction_type: TRANSACTION_TYPE.EXPENSE,
        chart_of_account_id: null,
        contact_id: null,
        memo: '',
        is_keep_memo: false,
    },
    rule_conditions: [
        {
            type: RULE_TYPE.DESCRIPTION,
            value: {
                operator: CONDITION_OPERATOR.CONTAINS,
                value: '',
            },
        },
    ],
    ...props.overwriteInitialValues,
}

const { useApiCreate } = useApiFactory('rules')
const { successNotify } = useNotification()
const { setErrors, handleSubmit } = useForm({
    initialValues,
})

const { execute: executeCreate } = useApiCreate()

const emitter = useEmitter()
const emit = defineEmits(['refresh-rules', 'rule-loading'])

const setErrorMessage = (name, value) => {
    const { setErrors: setError } = useField(name)
    setError(value)
}

watch(
    () => props.setHighlight,
    (value) => {
        Object.keys(value).forEach((key) => {
            setErrorMessage(key, value[key])
        })
    },
    { immediate: true, deep: true }
)

const onSave = handleSubmit(async (values) => {
    try {
        emit('rule-loading', true)
        emitter.emit('set-loading', true)
        await executeCreate(values)

        successNotify({
            title: t('general.success'),
            text: t('rules.create.createSuccess'),
        })
        emit('refresh-rules')
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emit('rule-loading', false)
        emitter.emit('set-loading', false)
    }
})

defineExpose({
    onSave,
})
</script>
