<template>
    <rules-form-general @set-errors="setErrors" />
</template>

<script setup>
import {
    TRANSACTION_TYPE,
    RULE_TYPE,
    CONDITION_OPERATOR,
} from '@tenant/utils/constants'

const { t } = useI18n()

const props = defineProps({
    ruleId: {
        type: String,
        required: true,
    },
})

const { useApiGet, setEntity, useApiSave } = useApiFactory('rules')
const { execute: executeGet } = useApiGet()
const { successNotify } = useNotification()
const { setErrors, handleSubmit, setValues } = useForm()

const { execute: executeSave } = useApiSave()

onMounted(async () => {
    await getRule(props.ruleId)
})

const getRule = async (id) => {
    try {
        emitter.emit('set-loading', true)
        emit('rule-loading', true)

        const rule = await executeGet(id)

        setValues({
            ...rule,
            rule_action: rule.rule_action ?? {
                transaction_type: TRANSACTION_TYPE.EXPENSE,
                chart_of_account_id: null,
                contact_id: null,
                memo: '',
                is_keep_memo: false,
            },
            rule_conditions:
                rule.rule_conditions && rule.rule_conditions.length
                    ? rule.rule_conditions
                    : [
                          {
                              type: RULE_TYPE.DESCRIPTION,
                              value: {
                                  operator: CONDITION_OPERATOR.CONTAINS,
                                  value: '',
                              },
                          },
                      ],
        })
    } finally {
        emitter.emit('set-loading', false)
        emit('rule-loading', false)
    }
}

const emitter = useEmitter()
const emit = defineEmits(['refresh-rules', 'rule-loading'])

const onSave = handleSubmit(async (values) => {
    try {
        emitter.emit('set-loading', true)
        emit('rule-loading', true)
        const rule = await executeSave(values)

        setEntity(rule)

        successNotify({
            title: t('general.success'),
            text: t('rules.edit.editSuccess'),
        })

        emit('refresh-rules')
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('set-loading', false)
        emit('rule-loading', false)
    }
})

defineExpose({
    onSave,
})
</script>
