export default {
    rules: {
        validation: {
            name: {
                required: 'This field is required',
            },
        },
        sidebar: {
            label: 'Rules',
            form: {
                header: {
                    create: 'Create rule',
                    edit: 'Edit rule',
                    supportingText:
                        'Rules only apply to unreviewed transactions.',
                },
            },
        },
        create: {
            createSuccess: 'Rule created successfully',
        },
        edit: {
            editSuccess: 'Rule updated successfully',
        },
        list: {
            title: 'Rules',
            deleteConfirmation: 'Enter the rule name to confirm deletion.',
            deleteSuccess: 'Rule deleted successfully',
            description: 'Add rules to categorize your transactions.',
            emptyText: 'No rules found',
            emptySubText: 'Create your first rule',
            allAccount: 'All accounts',
            button: {
                newRule: 'New rule',
                createRule: 'Create a rule',
            },
            countRules: 'No rules | 1 rule | {count} rules',
            list: {
                BatchActions: 'Batch Actions',
            },
            columns: {
                priority: 'Priority',
                ruleName: 'Rule name',
                appliedTo: 'Applied to',
                conditions: 'Conditions',
                settings: 'Settings',
            },
            notFound: {
                title: 'No rules found',
                description:
                    'Make rules for your frequently occurring transactions and tell Cybooks exactly what should happen when conditions are met.',
            },
        },
        moneyFlowTypes: {
            in: 'Money in',
            out: 'Money out',
        },
        transactionTypes: {
            incoming: 'Incoming',
            outgoing: 'Outgoing',
        },
        conditions: {
            ruleType: {
                description: 'Description',
                bankText: 'Bank text',
                amount: 'Amount',
            },
            operator: {
                contains: 'Contains',
                not_contains: "Doesn't contain",
                exact: 'Is exactly',
                not_equals: "Doesn't equal",
                equals: 'Equals',
                greater_than: 'Is greater than',
                less_than: 'Is less than',
            },
            matchType: {
                all: 'All',
                any: 'Any',
            },
        },
        action: {
            description: 'Set Chart of Account to {chartOfAccount}',
        },
        form: {
            checkboxAutoAdd: {
                label: 'Auto-add',
                supportingText:
                    'Automatically confirm transactions this rule applies to',
            },
            name: {
                label: 'What do you want to call this rule?',
                placeholder: {
                    label: 'Hellenic Fees',
                },
            },
            includeOptions: {
                label: 'and include the following:',
            },
            addCondition: {
                label: 'Add a condition',
            },
            transactionType: {
                label: 'Transaction type',
            },
            chartOfAccount: {
                label: 'Chart of Account',
            },
            recommend: {
                label: '(Recommended)',
            },
            payee: {
                label: 'Payee',
            },
            description: {
                label: 'Replace bank memo',
                append: 'Append to bank memo',
                placeholder: {
                    label: 'Hellenic Bank Fee for 100.00 EUR Sent to EAC',
                },
            },
            checkboxDescription: {
                label: 'Also keep existing bank memo.',
            },
            allAccount: {
                label: 'All bank accounts',
            },
            clear: {
                label: 'Clear',
            },
            in: {
                label: 'in',
            },
            assign: {
                label: 'Then assign',
            },
            testRule: {
                label: 'Test rule',
                result: 'This rule will not apply to any unreviewed transactions. | This rule will apply to only one unreviewed transaction. | This rule will apply to {count} currently unreviewed transactions.',
            },
        },
    },
}
