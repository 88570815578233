<template>
    <div class="flex flex-col items-center gap-6 py-6">
        <form-text-input
            v-model="name"
            :label="$t('rules.form.name.label')"
            size="md"
            type="text"
            :error-message="nameErrorMessage"
            :placeholder="$t('rules.form.name.placeholder.label')"
        />

        <div class="h-px w-full border bg-gray-200 lg:w-full"></div>

        <div
            class="flex w-full flex-col items-center justify-between gap-4 sm:flex-row-reverse"
        >
            <form-single-select
                v-model="paymentAccountId"
                :placeholder="$t('rules.form.allAccount.label')"
                :error-message="paymentAccountIdErrorMessage"
                :options="paymentAccounts"
                :loading="loadingPaymentAccounts"
            />
            <div class="text-base">{{ $t('rules.form.in.label') }}</div>

            <form-single-select
                v-model="moneyFlowType"
                :placeholder="$t('rules.moneyFlowTypes.in')"
                :error-message="moneyFlowTypeErrorMessage"
                :options="moneyFlowTypes"
            />
        </div>
        <div
            class="flex w-full flex-col items-center justify-between gap-4 sm:flex-row"
        >
            <form-control-label
                class="shrink-0"
                :label="$t('rules.form.includeOptions.label')"
            />
            <form-single-select
                v-model="conditionsMatchType"
                size="md"
                :placeholder="$t('rules.conditions.matchType.all')"
                :error-message="conditionsMatchTypeErrorMessage"
                :options="conditionsMatchTypes"
            />
        </div>

        <div
            class="flex w-full flex-col items-center justify-between gap-4 sm:flex-row"
        >
            <form-field-array
                class="w-full"
                :fields="ruleConditions"
                :label-add="$t('rules.form.addCondition.label')"
                @on-remove-item="onRemoveItem"
                @on-add-item="onAddItem"
            >
                <template #default="{ item: { value, key } }">
                    <form-control-group-row>
                        <form-field-array-error
                            :name="`rule_conditions[${key}].type`"
                            #="{ errorMessage }"
                        >
                            <form-single-select
                                v-model="value.type"
                                :placeholder="
                                    $t('rules.conditions.ruleType.description')
                                "
                                :error-message="errorMessage"
                                :block="false"
                                :options="ruleConditionTypes"
                                @blur="
                                    resetFieldError(
                                        `rule_conditions[${key}].type`
                                    )
                                "
                            />
                        </form-field-array-error>
                        <form-field-array-error
                            :name="`rule_conditions[${key}].value.operator`"
                            #="{ errorMessage }"
                        >
                            <form-single-select
                                v-if="value.type !== 'amount'"
                                v-model="value.value.operator"
                                :block="false"
                                :placeholder="
                                    $t('rules.conditions.operator.contains')
                                "
                                :options="ruleOperatorsForText"
                                @blur="
                                    resetFieldError(
                                        `rule_conditions[${key}].value.operator`
                                    )
                                "
                            />
                            <form-single-select
                                v-else
                                v-model="value.value.operator"
                                :placeholder="
                                    $t('rules.conditions.operator.equals')
                                "
                                :options="ruleOperatorsForNumber"
                                @blur="
                                    resetFieldError(
                                        `rule_conditions[${key}].value.operator`
                                    )
                                "
                            />
                        </form-field-array-error>
                        <form-field-array-error
                            :name="`rule_conditions[${key}].value.value`"
                            #="{ errorMessage }"
                        >
                            <form-text-input
                                v-if="value.type !== 'amount'"
                                v-model="value.value.value"
                                placeholder="Hellnic Bank Fees"
                                :error-message="errorMessage"
                                @blur="
                                    resetFieldError(
                                        `rule_conditions[${key}].value.value`
                                    )
                                "
                            />
                            <form-number-input
                                v-else
                                v-model="value.value.value"
                                :precision="2"
                                :error-message="errorMessage"
                                @blur="
                                    resetFieldError(
                                        `rule_conditions[${key}].value.value`
                                    )
                                "
                            />
                        </form-field-array-error>
                    </form-control-group-row>
                </template>
            </form-field-array>
            <form-control-hint-text
                :error-message="ruleConditionsErrorMessage"
            />
        </div>

        <div class="flex w-full flex-row items-start items-center gap-2.5">
            <!-- TODO: AC-293 Button component colors missing -->
            <base-button
                variant="secondary-color"
                size="sm"
                @click="callTestRule"
                :loading="loadingTestRule"
                >{{ $t('rules.form.testRule.label') }}</base-button
            >
            <div class="text-sm text-gray-500">
                {{
                    !loadingTestRule
                        ? $t(
                              'rules.form.testRule.result',
                              totalTransactionsAffected
                          )
                        : ''
                }}
            </div>
        </div>

        <div class="h-px w-full border bg-gray-200 lg:w-full"></div>

        <div class="flex w-full flex-row items-start">
            <form-single-select
                class="sm:w-fit"
                :placeholder="$t('rules.form.assign.label')"
                :options="assigns"
                :model-value="assigns[0].value"
            />
        </div>
        <div v-if="ruleAction" class="flex w-full flex-col gap-6">
            <div class="flex flex-col items-center gap-4 sm:flex-row">
                <form-control-hint-text
                    :error-message="ruleActionErrorMessage"
                />
                <form-field-array-error
                    name="rule_action.transaction_type"
                    #="{ errorMessage }"
                >
                    <form-control-label
                        class="sm:w-2/12"
                        :label="$t('rules.form.transactionType.label')"
                    />
                    <form-single-select
                        v-model="ruleAction.transaction_type"
                        class="sm:w-fit"
                        :placeholder="$t('rules.transactionTypes.outgoing')"
                        :options="transactionTypes"
                        :error-message="errorMessage"
                    />
                </form-field-array-error>
            </div>
            <form-field-array-error
                name="rule_action.chart_of_account_id"
                #="{ errorMessage }"
            >
                <div class="flex flex-col items-center gap-4 sm:flex-row">
                    <form-control-label
                        class="sm:w-2/12"
                        :label="$t('rules.form.chartOfAccount.label')"
                    />
                    <form-detail-chart-of-account
                        class="w-full sm:w-fit"
                        :key="
                            ruleAction.transaction_type ===
                            TRANSACTION_TYPE.EXPENSE
                                ? 'expense'
                                : 'income'
                        "
                        :type="
                            ruleAction.transaction_type ===
                            TRANSACTION_TYPE.EXPENSE
                                ? 'expense'
                                : 'income'
                        "
                        v-model="ruleAction.chart_of_account_id"
                        :input-display="
                            getChartOfAccountName(ruleAction.chart_of_account)
                        "
                        :placeholder="$t('rules.form.chartOfAccount.label')"
                        :error-message="errorMessage"
                        :hide-message="true"
                        :include-modal="false"
                        @blur="
                            resetFieldError(`rule_action.chart_of_account_id`)
                        "
                        @open-modal="onOpenAddModal(ruleAction)"
                    />
                </div>
            </form-field-array-error>
            <div class="flex flex-col items-center gap-4 sm:flex-row">
                <form-field-array-error
                    name="rule_action.contact_id"
                    #="{ errorMessage }"
                >
                    <form-control-label
                        class="sm:w-2/12"
                        :label="$t('rules.form.payee.label')"
                    />
                    <form-contact-select
                        class="!w-auto"
                        v-model="ruleAction.contact_id"
                        :placeholder="$t('rules.form.recommend.label')"
                        :error-message="errorMessage"
                        :create-contact="false"
                        :loading="loadingContacts"
                        :input-display="getContactName(ruleAction?.contact)"
                        :show-cus-no="false"
                    />
                </form-field-array-error>
            </div>
        </div>
        <div v-if="ruleAction" class="flex w-full flex-col gap-6">
            <div class="flex flex-col items-center gap-4 sm:flex-row">
                <form-field-array-error
                    name="rule_action.memo"
                    #="{ errorMessage }"
                >
                    <form-control-label
                        class="sm:w-2/12"
                        :label="
                            !ruleAction.is_keep_memo
                                ? $t('rules.form.description.label')
                                : $t('rules.form.description.append')
                        "
                    />
                    <div class="flex flex-col gap-4 sm:w-10/12">
                        <form-textarea
                            v-model="ruleAction.memo"
                            :error-message="errorMessage"
                            size="md"
                            type="text"
                            :placeholder="
                                $t('rules.form.description.placeholder.label')
                            "
                        />
                        <form-checkbox
                            v-model="ruleAction.is_keep_memo"
                            :text="$t('rules.form.checkboxDescription.label')"
                            variant="checkbox"
                        />
                    </div>
                </form-field-array-error>
            </div>
        </div>
        <div class="h-px w-full border bg-gray-200 lg:w-full"></div>
        <div class="flex w-full">
            <form-checkbox
                v-model="autoAdd"
                :text="$t('rules.form.checkboxAutoAdd.label')"
                variant="checkbox"
            >
                <template #supportingText>
                    <p class="ml-2 text-sm text-gray-500">
                        {{ $t('rules.form.checkboxAutoAdd.supportingText') }}
                    </p>
                </template>
            </form-checkbox>
        </div>

        <bookeeping-accounts-add-modal
            :show="showAddModal"
            @modal-close="onCloseAddModal"
            @on-finished="onFinished"
        />
        <!--        <div class="flex w-full flex-row items-start gap-2.5">-->
        <!--            <div-->
        <!--                class="cursor-pointer text-sm text-primary-700"-->
        <!--                @click="onResetForm"-->
        <!--            >-->
        <!--                {{ $t('rules.form.clear.label') }}-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script setup>
import {
    MONEY_FLOW,
    MATCHING_TYPE,
    RULE_TYPE,
    CONDITION_OPERATOR,
    TRANSACTION_TYPE,
} from '@tenant/utils/constants'
import { getChartOfAccountName, getContactName } from '@tenant/utils/helper'
import { useCOAModal } from '@tenant/composables/apis/use-chart-of-accounts'

const { t } = useI18n()

const { value: name, errorMessage: nameErrorMessage } = useField('name')
const { value: moneyFlowType, errorMessage: moneyFlowTypeErrorMessage } =
    useField('money_flow_type')
const { value: paymentAccountId, errorMessage: paymentAccountIdErrorMessage } =
    useField('payment_account_id')
const { value: autoAdd } = useField('auto_add')
const {
    value: conditionsMatchType,
    errorMessage: conditionsMatchTypeErrorMessage,
} = useField('conditions_match_type')
const { value: ruleAction, errorMessage: ruleActionErrorMessage } =
    useField('rule_action')
const { errorMessage: ruleConditionsErrorMessage } = useField('rule_conditions')
const {
    remove,
    push,
    fields: ruleConditions,
} = useFieldArray('rule_conditions')
const { showAddModal, onCloseAddModal, onOpenAddModal, onFinished } =
    useCOAModal()

const { execute: executeGetPaymentAccounts, loading: loadingPaymentAccounts } =
    useApi('/api/payment-accounts', 'GET')
const { execute: executeTestRule, loading: loadingTestRule } = useApi(
    '/api/test-rules',
    'POST'
)

const { execute: executeGetContacts, loading: loadingContacts } = useApi(
    '/api/contacts/all',
    'GET'
)

const paymentAccounts = ref([
    {
        value: null,
        label: t('rules.form.allAccount.label'),
    },
])
const contacts = ref([])

const assigns = ref([
    {
        value: 'assign',
        label: t('rules.form.assign.label'),
    },
])

const moneyFlowTypes = ref([
    {
        value: MONEY_FLOW.IN,
        label: t('rules.moneyFlowTypes.in'),
    },
    {
        value: MONEY_FLOW.OUT,
        label: t('rules.moneyFlowTypes.out'),
    },
])

const conditionsMatchTypes = ref([
    {
        value: MATCHING_TYPE.ALL,
        label: t('rules.conditions.matchType.all'),
    },
    {
        value: MATCHING_TYPE.ANY,
        label: t('rules.conditions.matchType.any'),
    },
])

const ruleConditionTypes = ref([
    {
        value: RULE_TYPE.DESCRIPTION,
        label: t('rules.conditions.ruleType.description'),
    },
    {
        value: RULE_TYPE.AMOUNT,
        label: t('rules.conditions.ruleType.amount'),
    },
    {
        value: RULE_TYPE.BANK_TEXT,
        label: t('rules.conditions.ruleType.bankText'),
    },
])

const ruleOperatorsForNumber = ref([
    {
        value: CONDITION_OPERATOR.EQUALS,
        label: t('rules.conditions.operator.equals'),
    },
    {
        value: CONDITION_OPERATOR.NOT_EQUALS,
        label: t('rules.conditions.operator.not_equals'),
    },
    {
        value: CONDITION_OPERATOR.LESS_THAN,
        label: t('rules.conditions.operator.less_than'),
    },
    {
        value: CONDITION_OPERATOR.GREATER_THAN,
        label: t('rules.conditions.operator.greater_than'),
    },
])

const ruleOperatorsForText = ref([
    {
        value: CONDITION_OPERATOR.CONTAINS,
        label: t('rules.conditions.operator.contains'),
    },
    {
        value: CONDITION_OPERATOR.NOT_CONTAINS,
        label: t('rules.conditions.operator.not_contains'),
    },
    {
        value: CONDITION_OPERATOR.EXACT,
        label: t('rules.conditions.operator.exact'),
    },
])

const transactionTypes = ref([
    {
        value: TRANSACTION_TYPE.EXPENSE,
        label: t('rules.transactionTypes.outgoing'),
    },
    {
        value: TRANSACTION_TYPE.INCOME,
        label: t('rules.transactionTypes.incoming'),
    },
])

onMounted(() => {
    executeGetPaymentAccounts().then((response) => {
        response.data.map((paymentAcc) =>
            paymentAccounts.value.push({
                value: paymentAcc.id,
                label: paymentAcc.name,
            })
        )
    })

    executeGetContacts().then((response) => {
        contacts.value = response.map((contact) => ({
            value: contact.id,
            label: contact.legal_name,
        }))
    })
})

const onRemoveItem = (idx) => {
    if (ruleConditions.value.length > 1) {
        remove(idx)
    }
}

const onAddItem = () => {
    push({
        type: RULE_TYPE.DESCRIPTION,
        value: {
            operator: CONDITION_OPERATOR.CONTAINS,
            value: '',
        },
    })
}

const emitter = useEmitter()
const emit = defineEmits(['set-errors'])

const resetFieldError = (field) => {
    emitter.emit('reset-field-error', field)
}

const totalTransactionsAffected = ref(0)

const callTestRule = () => {
    executeTestRule({
        data: {
            money_flow_type: moneyFlowType.value,
            payment_account_id: paymentAccountId.value,
            conditions_match_type: conditionsMatchType.value,
            rule_conditions: ruleConditions.value.map((condition) => ({
                type: condition.value.type,
                value: {
                    operator: condition.value.value.operator,
                    value: condition.value.value.value,
                },
            })),
        },
    })
        .then(({ count }) => {
            totalTransactionsAffected.value = count
        })
        .catch(({ errors }) => {
            emit('set-errors', errors)
        })
}
</script>
