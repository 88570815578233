<template>
    <slide-out-menu
        @close="onCloseRulesSlideOut"
        :title="t('rules.sidebar.form.header.create')"
        :subtitle="t('rules.sidebar.form.header.supportingText')"
        :show="isShow"
        :loading="loading"
    >
        <rules-create-form
            @refreshRules="refreshRule"
            :set-highlight="setHighlight"
            :overwrite-initial-values="overwriteInitialValues"
            ref="ruleRef"
            @rule-loading="loading = $event"
        />

        <template v-slot:slide-out-menu-footer>
            <div class="flex flex-row-reverse content-end gap-3">
                <base-button size="sm" @click="onSave">
                    {{ $t('slideOutMenu.save.label') }}
                </base-button>
                <base-button
                    variant="secondary-color"
                    size="sm"
                    @click.prevent="onCloseRulesSlideOut"
                >
                    {{ $t('slideOutMenu.cancel.label') }}
                </base-button>
            </div>
        </template>
    </slide-out-menu>
</template>

<script setup>
const { t } = useI18n()
const ruleRef = ref(null)

const emit = defineEmits(['slide-close', 'refresh-rules'])
const onCloseRulesSlideOut = () => {
    emit('slide-close')
}

const loading = ref(false)

defineProps({
    isShow: {
        type: Boolean,
        required: true,
    },
    overwriteInitialValues: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    setHighlight: {
        type: Object,
        required: false,
        default: () => ({}),
    },
})

const refreshRule = () => {
    onCloseRulesSlideOut()
    emit('refresh-rules')
}

const onSave = () => {
    ruleRef.value.onSave()
}
</script>
