<template>
    <base-sticky-heading class="min-h-[82px] items-center">
        <template #title>
            <div class="flex flex-col gap-1">
                <div class="flex flex-row gap-x-2 gap-y-1">
                    <div class="text-lg font-medium text-gray-900">
                        {{ $t('rules.list.title') }}
                    </div>

                    <base-badge
                        :label="$t('rules.list.countRules', rules.total)"
                    />
                </div>
                <div class="text-sm font-normal text-gray-500">
                    {{ $t('rules.list.description') }}
                </div>
            </div>
        </template>

        <template #right>
            <div class="flex flex-row-reverse gap-3">
                <base-button
                    v-if="$acl.can('create_rules')"
                    variant="primary"
                    size="sm"
                    icon="line-icons/general/plus"
                    :label="$t('rules.list.button.newRule')"
                    @click="newRule"
                >
                </base-button>
            </div>
        </template>

        <data-grid
            :showTableHeader="rules?.data?.length > 0"
            :data-source="rules.data"
            :columns="columns"
            :loading="loadingSearch"
            :empty-text="$t('rules.list.emptyText')"
            :empty-sub="$t('rules.list.emptySubText')"
            table-borderless
        >
            <template #empty>
                <rules-list-empty @open-rule-slide="newRule" />
            </template>

            <template #column-appliedTo="{ item }">
                <base-badge
                    v-if="!item?.payment_account"
                    :label="$t('rules.list.allAccount')"
                    variant="success"
                    size="md"
                />

                <base-badge
                    v-else
                    :label="item?.payment_account?.name"
                    size="md"
                />
            </template>

            <template #column-conditions="{ item }">
                <template v-if="item?.rule_conditions?.length">
                    {{ renderCondition(item.rule_conditions[0]) }}
                </template>
            </template>

            <template #column-ruleAction="{ item }">
                <template v-if="item?.rule_action?.chart_of_account?.name">
                    {{
                        $t('rules.action.description', {
                            chartOfAccount:
                                item.rule_action.chart_of_account.name,
                        })
                    }}
                </template>
            </template>

            <template #filter>
                <div class="flex w-full items-center justify-between gap-2">
                    <div class="grow">
                        <base-button-group>
                            <base-button variant="default">
                                {{ $t('rules.list.list.BatchActions') }}
                            </base-button>
                        </base-button-group>
                    </div>
                    <div class="flex grow gap-3">
                        <rules-filter-search />
                        <base-button
                            variant="default"
                            icon="line-icons:general:filter-lines"
                        >
                            {{ $t('general.filters') }}
                        </base-button>
                    </div>
                </div>
            </template>

            <template #column-selector="{ item }">
                <form-checkbox variant="checkbox" v-model="item.selector" />
            </template>

            <template #column-settings>
                <base-button
                    icon="line-icons:general:edit-01"
                    variant="tertiary-gray"
                    size="sm"
                />
                <base-button
                    icon="line-icons:general:trash-01"
                    variant="tertiary-gray"
                    size="sm"
                />
            </template>

            <template #column-action="{ item }">
                <base-context-menu>
                    <base-context-menu-item
                        v-if="$acl.can('update_rules')"
                        icon="line-icons:general:archive"
                        @click="getRule(item.id)"
                    >
                        {{ $t('general.edit') }}
                    </base-context-menu-item>

                    <base-context-menu-item
                        v-if="$acl.can('delete_rules')"
                        icon="line-icons:general:trash-01"
                        @click="setEntity(item)"
                    >
                        {{ $t('general.delete') }}
                    </base-context-menu-item>
                </base-context-menu>
            </template>

            <template #footer>
                <grid-pagination :total="rules.last_page" :page="10" />
            </template>
        </data-grid>
    </base-sticky-heading>

    <rules-create
        v-if="!ruleId"
        @slide-close="onCloseRuleSlide"
        @refresh-rules="executeRefreshSearch"
        :is-show="showRuleSlideOutMenu"
    />
    <rules-edit
        v-else
        @slide-close="onCloseRuleSlide"
        @refresh-rules="executeRefreshSearch"
        :is-show="showRuleSlideOutMenu"
        :rule-id="ruleId"
    />
    <base-delete-modal
        :loading="loadingDelete"
        :show="deleteRule?.id"
        :name="deleteRule?.name"
        :confirmation-message="$t('rules.list.deleteConfirmation')"
        :confirmation-value="deleteRule?.name"
        @modal-close="setEntity(null)"
        @confirm-delete="confirmDelete"
    />
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const route = useRoute()

const { t } = useI18n()
const {
    entities: rules,
    useApiSearch,
    useApiDelete,
    setEntity,
    entity: deleteRule,
} = useApiFactory('rules')
const { loading: loadingSearch, executeRefreshSearch } = useApiSearch()
const { loading: loadingDelete, execute: executeDelete } = useApiDelete()
const { successNotify } = useNotification()
const showRuleSlideOutMenu = ref(false)
const ruleId = ref(null)

const confirmDelete = () => {
    executeDelete().then(() => {
        successNotify({
            title: t('general.success'),
            text: t('rules.list.deleteSuccess'),
        })

        executeRefreshSearch()
    })
}

const onOpenRuleSlide = () => {
    showRuleSlideOutMenu.value = true
}

const onCloseRuleSlide = () => {
    showRuleSlideOutMenu.value = false
}

const getRule = (id) => {
    ruleId.value = id

    onOpenRuleSlide()
}

const newRule = () => {
    ruleId.value = null

    onOpenRuleSlide()
}

const columns = [
    {
        property: 'selector',
        checkBox: 'line-icons:general:minus-square',
    },
    {
        property: 'priority',
        tooltip: true,
        label: t('rules.list.columns.priority'),
    },
    {
        property: 'name',
        label: t('rules.list.columns.ruleName'),
    },
    {
        property: 'appliedTo',
        label: t('rules.list.columns.appliedTo'),
    },
    {
        property: 'conditions',
        label: t('rules.list.columns.conditions'),
    },
    {
        property: 'ruleAction',
        label: t('rules.list.columns.settings'),
    },
]

const renderCondition = (condition) => {
    const type = t('rules.conditions.ruleType.' + condition.type)
    const operator = t(
        'rules.conditions.operator.' + condition.value.operator
    ).toLowerCase()

    let value =
        typeof condition.value.value === 'string'
            ? `"${condition.value.value}"`
            : condition.value.value
    if (value.length > 15) {
        value = value.substring(0, 20) + '...'
    }

    return `${type} ${operator} ${value}`
}

watch(
    () => route.query,
    (query) => {
        if (query?.id) {
            getRule(query.id)
        }
    },
    { immediate: true }
)
</script>
